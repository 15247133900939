import SplashLoader from "@common/Splash";
import useAuth from "@hooks/useAuth";
import useDeviceType from "@hooks/useDeviceType";
import useHotjar from "@hooks/useHotjar";
import ROUTES from "@routes/constants";
import * as Sentry from "@sentry/react";
import { useAuthStore } from "@store/storeAuth";
import { storeDevice } from "@store/storeDevice";
import { AnimatePresence } from "framer-motion";
import LogRocket from "logrocket";
import React, { Suspense, useEffect } from "react";
import ReactGA from "react-ga4";
import { Navigate, Route, Routes } from "react-router-dom";
import { UpdateProvider } from "./container/provider/UpdateProvider";
import Error from "./error/Error";
import ApiLoader from "./loader/Loader";
import useFirebaseNotifications from "@hooks/useInitializeFirebase";
import { Device } from "@capacitor/device";

// LAZY LOADING COMPONENTS
const SignUp = React.lazy(() => import("./app/auth/signUp"));
const SuccessPage = React.lazy(() => import("./app/successPage"));
const IDLoadSuccessPage = React.lazy(() => import("./app/IDLoadSuccessPage"));
const BillLoadPage = React.lazy(() => import("./app/billLoadPage"));
const OnBoardingPage = React.lazy(() => import("./app/onBoardingPage"));
const Index = React.lazy(() => import("@common/index"));
const IDLoadPage = React.lazy(() => import("./app/IDLoadPage"));
const OtpResultPage = React.lazy(() => import("./app/otpResultPage"));
const OtpPage = React.lazy(() => import("./app/otpPage"));
const Login = React.lazy(() => import("./app/auth/logIn"));
const SignaturePage = React.lazy(() => import("./app/signaturePage"));
const Home = React.lazy(() => import("./app/home"));
const PendingPage = React.lazy(() => import("./app/pendingPage"));
const PrivateRoute = React.lazy(
  () => import("./routes/containers/PrivateRoute")
);
const ContractsPage = React.lazy(() => import("./app/contractsPage"));
const ContractDetail = React.lazy(() => import("./app/contractDetail"));
const FriendsPage = React.lazy(() => import("./app/friendsPage"));
const ProfilePage = React.lazy(() => import("./app/profilePage"));
const ProfileModify = React.lazy(
  () => import("./app/profilePage/containers/Modify")
);
const Profile = React.lazy(
  () => import("./app/profilePage/containers/Profile")
);
const NotificationPage = React.lazy(() => import("./app/notificationPage"));
const ResetPasswordPage = React.lazy(() => import("./app/resetPasswordPage"));
const PaymentMethodPage = React.lazy(() => import("./app/paymentMethodPage"));
const AuthOnbordingNoSign = React.lazy(
  () => import("./app/AuthOnbordingNoSign")
);

const AppComponent: React.FC = () => {
  useDeviceType();
  const { isLoading } = useAuth();
  const { device } = storeDevice();
  const { user } = useAuthStore();

  // if (user) {
  useFirebaseNotifications();
  // };
  ReactGA.initialize("G-H2RKKPZ629");
  useHotjar("5084301", "6");

  const isOnboardingNoSign = localStorage.getItem("isOnboarding_without_sign");

  useEffect(() => {
    if (user) {
      // Enrich Sentry user info
      Sentry.setUser({
        id: user.id || "unknown_user_id",
        email: user.email || "unknown_user_email",
        name: `${user.name} ${user?.surname}` || "unknown_user_name",
      });

      // Enrich crisp user info
      const userInfo = [
        ["set", "user:email", [user.email]],
        ["set", "user:nickname", [`${user.name} ${user.surname}`]],
        ["set", "user:phone", [user.phone]],
      ];

      if (window.$crisp) {
        userInfo.forEach((info) => window.$crisp.push(info));
      } else {
        window.$crisp = [];
        userInfo.forEach((info) => window.$crisp.push(info));
      }
    }
  }, [user]);

  LogRocket.init("buqatg/nicov");

  return (
    <>
      {(device.platform == "ios" || device.platform == "android") && (
        <UpdateProvider />
      )}
      <SplashLoader isLoading={isLoading} />
      <Suspense
        fallback={
          <div aria-live="polite" style={{ visibility: "hidden" }}>
            Loading...
          </div>
        }
      >
        <Error />
        <ApiLoader />
        <AnimatePresence mode="wait" initial={false}>
          {!isLoading && (
            <Routes>
              {/** Common Route */}
              <Route path={ROUTES.COMMON} element={<Index />} />
              <Route path={"/"} element={<Navigate to={ROUTES.SIGN_IN} />} />
              <Route path={ROUTES.SIGN_UP} element={<SignUp />} />
              <Route path={ROUTES.SIGN_IN} element={<Login />} />
              <Route
                path={ROUTES.ONBOARDING}
                element={
                  <PrivateRoute
                    RouteComponent={OnBoardingPage}
                    onBoardingPage={true}
                  />
                }
              />
              <Route
                path={ROUTES.BILL_LOAD}
                element={
                  <PrivateRoute
                    RouteComponent={BillLoadPage}
                    onBoardingPage={true}
                  />
                }
              />
              <Route
                path={ROUTES.OTP}
                element={
                  <PrivateRoute
                    RouteComponent={OtpPage}
                    onBoardingPage={true}
                  />
                }
              />
              <Route
                path={ROUTES.OTP_RESULT}
                element={
                  <PrivateRoute
                    RouteComponent={OtpResultPage}
                    onBoardingPage={true}
                  />
                }
              />
              <Route
                path={ROUTES.RESET_PASSWORD}
                element={<ResetPasswordPage />}
              />
              <Route
                path={ROUTES.AUTH_ONBOARDING_NO_SIGN}
                element={<AuthOnbordingNoSign />}
              />

              {/** Normal Route with login normal can access*/}
              {!isOnboardingNoSign && (
                <>
                  <Route
                    path={ROUTES.ID_LOAD}
                    element={
                      <PrivateRoute
                        RouteComponent={IDLoadPage}
                        onBoardingPage={true}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.ID_LOAD_SUCCESS}
                    element={
                      <PrivateRoute
                        RouteComponent={IDLoadSuccessPage}
                        onBoardingPage={true}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.SIGNATURE}
                    element={
                      <PrivateRoute
                        RouteComponent={SignaturePage}
                        onBoardingPage={true}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.SUCCESS}
                    element={
                      <PrivateRoute
                        RouteComponent={SuccessPage}
                        onBoardingPage={true}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.HOME}
                    element={
                      <PrivateRoute
                        RouteComponent={Home}
                        onBoardingPage={false}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.PENDING}
                    element={
                      <PrivateRoute
                        RouteComponent={PendingPage}
                        onBoardingPage={false}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.CONTRACTS}
                    element={
                      <PrivateRoute
                        RouteComponent={ContractsPage}
                        onBoardingPage={false}
                      />
                    }
                  />
                  <Route
                    path={`${ROUTES.CONTRACT_DETAIL}/:id`}
                    element={
                      <PrivateRoute
                        RouteComponent={ContractDetail}
                        onBoardingPage={false}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.FRIENDS}
                    element={
                      <PrivateRoute
                        RouteComponent={FriendsPage}
                        onBoardingPage={false}
                      />
                    }
                  />
                  <Route
                    path={ROUTES.PROFILE}
                    element={
                      <PrivateRoute
                        RouteComponent={ProfilePage}
                        onBoardingPage={false}
                      />
                    }
                  >
                    <Route
                      path={ROUTES.PROFILE_DETAILS}
                      element={<Profile />}
                    />
                    <Route
                      path={ROUTES.PROFILE_MODIFY}
                      element={<ProfileModify />}
                    />
                  </Route>
                  <Route
                    path={ROUTES.NOTIFICATION}
                    element={
                      <PrivateRoute
                        RouteComponent={NotificationPage}
                        onBoardingPage={false}
                      />
                    }
                  />

                  <Route
                    path={`${ROUTES.PAYMENT_METHOD}/:id`}
                    element={
                      <PrivateRoute
                        RouteComponent={PaymentMethodPage}
                        onBoardingPage={false}
                      />
                    }
                  />
                  <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
                </>
              )}

              {/** Onboarding without sign */}
              {isOnboardingNoSign && (
                <>
                  {/* not have norma login route, just the normale and the redirect is on onboarding page */}
                  <Route
                    path="*"
                    element={<Navigate to={ROUTES.ONBOARDING} />}
                  />
                </>
              )}
            </Routes>
          )}
        </AnimatePresence>
      </Suspense>
    </>
  );
};

export default AppComponent;
