const ROUTES = {
  COMMON: "/common",
  HOME: "/home",
  SIGN_UP: "/signup",
  SIGN_IN: "/login",
  ONBOARDING: "/onboarding",
  BILL_LOAD: "/bill_load",
  OTP: "/otp",
  OTP_RESULT: "/otp_result",
  ID_LOAD: "/id_load",
  ID_LOAD_SUCCESS: "/id_load_success",
  SIGNATURE: "/signature",
  SUCCESS: "/success",
  PENDING: "/contracts/pending",
  CONTRACTS: "/contracts/list",
  CONTRACT_DETAIL: "/contract",
  FRIENDS: "/friends",
  PROFILE: "/profile",
  PROFILE_DETAILS: "profile",
  PROFILE_MODIFY: "modify",
  NOTIFICATION: "/notification",
  RESET_PASSWORD: "/reset_password",
  PAYMENT_METHOD: "/payment_method",
  AUTH_ONBOARDING_NO_SIGN: "/calcola_free",
};

export default ROUTES;
